import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import { Layout } from "../components/layout"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"

const ContactPage = ({ data }) => {
    const intl = useIntl()

    const content =
        intl.locale === "ja" ? data.allContentfulContactPage.nodes[0] : data.allContentfulContactPage.nodes[1]
    return (
        <Layout>
            <div css={wrapper}>
                <h6 css={headline}>{content.headline}</h6>
                <form
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    action="/thanks"
                >
                    <div>
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="bot-field" />
                        <div>
                            <div css={formGroup}>
                                <p>{intl.formatMessage({ id: "contact_name" })}</p>
                                <label>
                                    <input
                                        type="text"
                                        css={formInput}
                                        id="name"
                                        name="name"
                                        maxLength="30"
                                        minLength="2"
                                        placeholder="Name"
                                        required
                                        autoComplete="name"
                                    />
                                </label>
                            </div>
                            <div css={formGroup}>
                                <p>{intl.formatMessage({ id: "contact_address" })}</p>
                                <label>
                                    <input
                                        type="email"
                                        css={formInput}
                                        id="email"
                                        name="email"
                                        placeholder="E-Mail"
                                        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                        required
                                        autoComplete="email"
                                    />
                                </label>
                            </div>
                            <div css={formGroup}>
                                <p>{intl.formatMessage({ id: "contact_subject" })}</p>
                                <label>
                                    <input
                                        type="text"
                                        css={formInput}
                                        id="title"
                                        name="title"
                                        placeholder="Subject"
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group form-textarea">
                                <p>{intl.formatMessage({ id: "contact_content" })}</p>
                                <label>
                                    <textarea
                                        css={textareaStyle}
                                        id="contact"
                                        name="content"
                                        rows="8"
                                        placeholder=""
                                        required
                                    ></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div css={submit}>
                        <button type="submit" css={submitButton}>
                            {intl.formatMessage({ id: "submit" })}
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}
export default ContactPage

const wrapper = css`
    width: 50vw;
    display: block;
    margin: 0 auto 20vh;
    padding-top: 10vh;

    @media ${device.tb} {
        width: 70vw;
    }
    @media ${device.sp} {
        width: 84vw;
    }
`
const headline = css`
    text-align: center;
    margin-bottom: 7vh;
    @media ${device.sp} {
        text-align: left;
    }
`
const formGroup = css`
    margin-bottom: 2rem;
    @media ${device.sp} {
        margin-bottom: 4rem;
    }
`
const formInput = css`
    border: solid 1px black;
    width: 100%;
    min-height: 3rem;
    padding: 0.4rem 1rem;
    font-size: 1.55rem;

    @media ${device.sp} {
        width: calc(100% - 2rem);
        min-height: 4rem;
    }
`
const submit = css`
    text-align: center;
    margin-top: 5vh;
`
const textareaStyle = css`
    ${formInput}
    padding: 1.4rem 1rem;
    height: 300px;
    line-height: 2.2rem;
    font-weight: 200;
`
const submitButton = css`
    color: white;
    background: black;
    padding: 1rem 5rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
    border-style: none;
    border-radius: 2px;
    font-weight: 200;
    span {
        font-size: 1rem;
        letter-spacing: 0.1rem;
        line-height: 1rem;
    }

    &:hover {
        opacity: 0.5;
    }

    @media ${device.sp} {
        padding: 1.8rem 5rem;
    }
`

export const query = graphql`
    query {
        allContentfulContactPage {
            nodes {
                headline
            }
        }
    }
`
